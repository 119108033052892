import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Anchor from "../../components/anchor"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import SectionSpacing from "../../components/section-spacing"
import Stack from "../../components/stack"
import TableOfContents from "../../components/table-of-contents"

import Einfuehrung from "../../content/hoelderlins-orte/einfuehrung"
import Attribution from "../../content/hoelderlins-orte/attribution"
import FotografienKlemm from "../../content/hoelderlins-orte/fotografien-klemm"
import Perspektiven from "../../content/hoelderlins-orte/perspektiven"
import TagNacht from "../../content/hoelderlins-orte/tag-nacht"
import Jahreszeiten from "../../content/hoelderlins-orte/jahreszeiten"
import Griechenland from "../../content/hoelderlins-orte/griechenland"
import FluesseBerge from "../../content/hoelderlins-orte/fluesse-berge"
import LebenInOrten from "../../content/hoelderlins-orte/leben-in-orten"

const HoelderlinsOrtePage = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "og_images/hoelderlins-orte.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
      }
    `
  )

  return (
    <Layout backdrop="orte">
      <Seo
        title="Hölderlins Orte"
        image={file.childImageSharp.gatsbyImageData}
      />
      <TableOfContents
        items={[
          {
            anchor: "fotografien-klemm",
            title: "Fotografien von Barbara Klemm",
          },
          {
            anchor: "perspektiven",
            title: "Hölderlins Perspektiven",
          },
          {
            anchor: "griechenland",
            title: "Sehnsuchtsort Griechenland",
          },
          {
            anchor: "fluesse-und-berge",
            title: "Flüsse und Berge",
          },
          {
            anchor: "jahreszeiten",
            title: "Zyklus der Jahreszeiten",
          },
          {
            anchor: "tag-und-nacht",
            title: "Tag und Nacht",
          },
          {
            anchor: "leben-in-orten",
            title: "Hölderlins Leben in Orten",
          },
        ]}
      />
      <Stack>
        <Stack>
          <Stack>
            <Breadcrumb
              items={[
                {
                  title: "Digital",
                  link: "/digital",
                },
                {
                  title: "Sonderausstellungen",
                  link: "/sonderausstellungen",
                },
                {
                  title: "Hölderlins Orte",
                  link: "/sonderausstellungen/hoelderlins-orte",
                },
              ]}
            />
            <PageTitle>Hölderlins Orte</PageTitle>
            <Stack space={3}>
              <Heading as="div" level={3}>
                Fotografien von Barbara Klemm
              </Heading>
              <Heading as="div" level={5} fontFamily="serif" bold={false}>
                Eine Wanderausstellung der Universitätsstadt Tübingen zum
                Jubiläumsjahr 2020
              </Heading>
            </Stack>
          </Stack>
          <Paragraph dropcap={true}>
            In der Ausstellung treffen Friedrich Hölderlins Verse und
            Landschaftsbeschreibungen auf Bilder der Fotografin Barbara Klemm.
            Eigens für die Ausstellung folgte die ehemalige FAZ-Fotografin mit
            der Kamera den Blickrichtungen aus Hölderlins Gedichten und bereiste
            die Orte, die sich in sein Leben und Werk eingeprägt haben. Auf
            diese Weise näherte sich die Fotografin, deren Werke in das
            ikonografische Gedächtnis der Bundesrepublik eingegangen sind, dem
            Dichter, der wie kein anderer die Grenzen der deutschen Sprache
            ausgelotet hat.
          </Paragraph>
        </Stack>
        <SectionSpacing>
          <Einfuehrung />
          <Anchor id="fotografien-klemm">
            <FotografienKlemm />
          </Anchor>
          <Anchor id="perspektiven">
            <Perspektiven />
          </Anchor>
          <Anchor id="griechenland">
            <Griechenland />
          </Anchor>
          <Anchor id="fluesse-und-berge">
            <FluesseBerge />
          </Anchor>
          <Anchor id="jahreszeiten">
            <Jahreszeiten />
          </Anchor>
          <Anchor id="tag-und-nacht">
            <TagNacht />
          </Anchor>
          <Anchor id="leben-in-orten">
            <LebenInOrten />
          </Anchor>
          <Attribution />
        </SectionSpacing>
      </Stack>
    </Layout>
  )
}

export default HoelderlinsOrtePage
