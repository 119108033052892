import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AudioPlayer from "../../components/audio-player"
import Constrain from "../../components/constrain"
import Container from "../../components/container"
import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import Intro from "../../components/intro"
import QuotePrimary from "../../components/quote-primary"
import Stack from "../../components/stack"
import Text from "../../components/text"
import Title from "../../components/title"

const FluesseBerge = () => {
  const data = useStaticQuery(graphql`
    query {
      neckarTuebingen: file(
        relativePath: { eq: "hoelderlins-orte/neckar-tuebingen.jpg" }
      ) {
        ...largeImage
      }
      rheinfall: file(
        relativePath: { eq: "hoelderlins-orte/rheinfall-schaffhausen.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Title top="Flüsse" middle="&" bottom="Berge" />
      <Intro>
        Berge und Flüsse haben eine unübersehbare Präsenz in Hölderlins
        Gedichtwerk. Als Mittler zwischen Nah und Fern, als Wegweiser aus dem
        Bekannten und Vertrauten ins Unbekannte, aus der Enge in die Weite,
        prägen die Flüsse die horizontale Blickrichtung seiner Gedichte. Die
        Berge wiederum verweisen in die Vertikale – sie ragen in den Himmel,
        berühren mit ihren Gipfeln die Wolken und scheinen somit eine besondere
        Nähe zu den Göttern zu haben.
      </Intro>
      <Container maxWidth="2xl">
        <Image
          image={data.neckarTuebingen.childImageSharp.gatsbyImageData}
          alt="Schwarzweiß-Fotografie des Neckars mit Booten und Stegen"
          caption="Neckar, Tübingen"
        />
      </Container>
      <Paragraph>
        Der Neckar war Hölderlins lebenslanger Begleiter. In Lauffen am Neckar
        wurde er geboren, in Nürtingen, ebenfalls am Neckar gelegen, wuchs er
        auf. Und in den letzten 36 Jahren seines Lebens hatte er diesen Fluss
        täglich vor Augen. Doch nicht nur der Neckar, auch die Donau, der Main,
        die Garonne und vor allem der Rhein sind in seine Gedichte eingegangen.
        »Man stelle sich vor – ein Strom der dreimal breiter ist, als der Nekar,
        wo er am breitsten ist – dieser Strom von oben herab an beiden Ufern von
        Wäldern beschattet – und weiter hinab die Aussicht über ihn so lang, daß
        einem der Kopf schwindelte – das war ein Anblik – ich werd' ihn nie
        vergessen, er rührte mich außerordentlich...«, berichtet Hölderlin von
        seiner ersten Begegnung mit dem Rhein.
      </Paragraph>
      <Image
        image={data.rheinfall.childImageSharp.gatsbyImageData}
        alt="Schwarzweiß-Fotografie vom schäumenden Rheinfall bei Schaffhausen"
        caption="Rheinfall Schaffhausen"
      />
      <Constrain align="right">
        <Stack>
          <QuotePrimary author="Friedrich Hölderlin: Der Rhein">
            <span>Ein Räthsel ist Reinentsprungenes. Auch</span>
            <span>Der Gesang kaum darf es enthüllen</span>
          </QuotePrimary>
          <AudioPlayer
            src={`sonderausstellungen/hoelderlins-orte/rhein`}
            title={
              <Text as="span" sans>
                Christian Reiner liest{" "}
                <Text bold as="strong">
                  ›Der Rhein‹
                </Text>
              </Text>
            }
          />
        </Stack>
      </Constrain>
      <Paragraph>
        Aus Hauptwil in der Schweiz schreibt Hölderlin an seine Schwester Rike:
        »Ich kann nur dastehn, wie ein Kind, und staunen und stille mich freuen,
        wenn ich draußen bin, auf dem nächsten Hügel, und wie vom Aether herab
        die Höhen alle näher und näher niedersteigen bis in dieses freundliche
        Thal, das überall an seinen Seiten mit den immergrünen Tannenwäldchen
        umkränzt, und in der Tiefe mit Seen und Bächen durchströmt ist, u. da
        wohne ich, in einem Garten, wo unter meinem Fenster Weiden und Pappeln
        an einem klaren Wasser stehen, das mir gar wohlgefällt des Nachts mit
        seinem Rauschen, wenn alles still ist, und ich vor dem heiteren
        Sternenhimmel dichte und sinne.« Vor dieser Kulisse entsteht Hölderlin
        einziger Versuch einer sapphische Ode –{" "}
        <Text as="span" italic>
          Unter den Alpen gesungen
        </Text>
        .
      </Paragraph>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Unter den Alpen gesungen.">
          <span>und frei will ich, so</span>
          <span>Lang ich darf, euch all, ihr Sprachen des Himmels!</span>
          <span>Deuten und singen.</span>
        </QuotePrimary>
      </Constrain>
    </Stack>
  )
}

export default FluesseBerge
