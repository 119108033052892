import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"

import { useColorMode } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Stack from "../../components/stack"
import Columns from "../../components/columns"
import Column from "../../components/column"
import Inline from "../../components/inline"
import Box from "../../components/box"
import Heading from "../../components/heading"
import Text from "../../components/text"
import Container from "../../components/container"
import Image from "../../components/image"
import Button from "../../components/button"
import logoTuebingen from "../../images/partner-logos/tuebingen.svg"
import logoHoelderlin2020 from "../../images/partner-logos/hoelderlin-2020.svg"
import logoBKM from "../../images/partner-logos/bkm.svg"
import logoTuebingenWhite from "../../images/partner-logos/tuebingen-white.svg"
import logoHoelderlin2020White from "../../images/partner-logos/hoelderlin-2020-white.svg"
import logoBKMWhite from "../../images/partner-logos/bkm-white.svg"

const Attribution = (props) => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(graphql`
    query Attribution {
      katalogKlemm: file(
        relativePath: { eq: "hoelderlins-orte/katalog-klemm.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Stack>
      <Stack space={3}>
        <Heading as="div" align="center">
          Bildnachweis
        </Heading>
        <Text align="center">
          Alle Bilder auf auf dieser Seite: © Barbara Klemm
        </Text>
      </Stack>
      <Box bg="muted" p={[6, 8, 12]}>
        <Stack>
          <Stack space={[6, 10]}>
            <Stack space={3}>
              <Text size={3} sans>
                Eine Ausstellung der Universitätsstadt Tübingen zum
                Hölderlin-Jubiläumsjahr 2020. Gefördert von der Beauftragten der
                Bundesregierung für Kultur und Medien.
              </Text>
            </Stack>
            <Inline space="12" alignY="center">
              <img
                src={colorMode === "light" ? logoTuebingen : logoTuebingenWhite}
                alt="Logo Tübingen"
                width="243"
                height="92"
                sx={{ width: "auto", height: "60px" }}
              />
              <img
                src={
                  colorMode === "light"
                    ? logoHoelderlin2020
                    : logoHoelderlin2020White
                }
                alt="Logo Hölderlin 2020"
                width="222"
                height="92"
                sx={{ width: "auto", height: "60px" }}
              />
              <img
                src={colorMode === "light" ? logoBKM : logoBKMWhite}
                alt="Logo BKM"
                width="300"
                height="92"
                sx={{ width: "auto", height: "60px" }}
              />
            </Inline>
          </Stack>
        </Stack>
      </Box>
      <Box bg="muted" p={[6, 8, 12]}>
        <Columns
          alignY="center"
          space={[6, 8, 12]}
          collapse={[true, false]}
          reverse={[false, true]}
        >
          <Column width="content">
            <Box width={["24", "40"]}>
              <Image
                image={data.katalogKlemm.childImageSharp.gatsbyImageData}
                alt="Titelbild des Ausstellungkatalogs"
              />
            </Box>
          </Column>
          <Column>
            <Stack space={6}>
              <Stack space={2}>
                <Heading as="h2" level={5}>
                  Der Katalog zur Ausstellung
                </Heading>
                <Container maxWidth="lg" center={false}>
                  <Text size={[2, 3]} sans>
                    Mit allen 43 Bildern der Ausstellung und den dazugehörigen
                    Texten von Friedrich Hölderlin. Erschienen im Kerber Verlag.
                  </Text>
                </Container>
              </Stack>
              <Stack space={1}>
                <Text size={1} sans>
                  ISBN 978-3-7356-0658-7
                </Text>
                <Text size={1} sans>
                  128 Seiten
                </Text>
              </Stack>
              <Button secondary href="https://www.tuebingen.de/1520.html#/1952">
                Jetzt bestellen
              </Button>
            </Stack>
          </Column>
        </Columns>
      </Box>
    </Stack>
  )
}

Attribution.propTypes = {}

export default Attribution
