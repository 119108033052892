import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Stack from "../../components/stack"
import Button from "../../components/button"
import Text from "../../components/text"
import Box from "../../components/box"
import Heading from "../../components/heading"
import Image from "../../components/image"

const BioKlemm = () => {
  const [state, setState] = useState({
    expanded: false,
  })

  const toggleReadMore = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    })
  }

  const data = useStaticQuery(graphql`
    {
      barbaraKlemm: file(
        relativePath: { eq: "hoelderlins-orte/barbara-klemm.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Box bg="muted">
      <Box pr={[12, 24]}>
        <Image
          image={data.barbaraKlemm.childImageSharp.gatsbyImageData}
          alt="Ein Schwarzweiß-Porträt zeigt Barbara Klemm"
        />
      </Box>
      <Box p={[6, 12]}>
        <Stack space={6}>
          <Heading level={4}>Barbara Klemm</Heading>
          <Text size={[2, 3]}>
            Als Fotografin für die Frankfurter Allgemeine Zeitung hat Barbara
            Klemm das Bildgedächtnis der deutschen Bundesrepublik mit geprägt.
          </Text>

          {!state.expanded ? (
            <Button onClick={toggleReadMore} secondary>
              Mehr über Barbara Klemm
            </Button>
          ) : (
            <Stack space={6}>
              <Text size={[2, 3]}>
                1939 in Münster/Westfalen geboren, begann sie 1959 für die FAZ
                zu arbeiten. Über 35 Jahre lang war sie als Redaktionsfotografin
                unterwegs – insbesondere in Ost- und Westeuropa und Südamerika.
                Längst haben sich ihre Fotografien in das ikonografische
                Gedächtnis der deutschen Teilung und Wiedervereinigung
                eingeschrieben, haben unser Bild von Politiker*innen,
                Künstler*innen und Personen des öffentlichen Lebens geprägt.
                Neben den großen, bedeutenden Gesichtern und zeitgeschichtlichen
                Ereignissen hat sie mit ihrer Kamera aber auch das scheinbar
                Unbedeutende – Alltagsmomente, Landschaften, Museen und
                Straßenszenen – eingefangen.
              </Text>
              <Text size={[2, 3]}>
                Seit 1992 ist sie Mitglied der Akademie der Künste
                Berlin-Brandenburg, seit 2000 hat sie eine Honorarprofessorin an
                der FH Darmstadt. Ihre Fotografien wurden in zahlreichen Museen
                in Deutschland sowie u.a. in Vietnam, Indien, Russland,
                Lateinamerika, USA, Südkorea, Italien und Frankreich
                ausgestellt. 2010 wurde sie in den Orden »Pour le mérite für
                Wissenschaften und Künste« aufgenommen.
              </Text>
            </Stack>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default BioKlemm
