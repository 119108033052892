import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Count from "../../components/count"
import Intro from "../../components/intro"
import Image from "../../components/image"
import Constrain from "../../components/constrain"
import Paragraph from "../../components/paragraph"

const TagNacht = () => {
  const data = useStaticQuery(graphql`
    query {
      mond: file(relativePath: { eq: "hoelderlins-orte/mond.jpg" }) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Title top="Tag" middle="&" bottom="Nacht" />
      <Intro>
        Hölderlins Alltag im Tübinger Turm war geprägt vom gleichmäßigen Verlauf
        der Tage, Wochen und Monate und der damit einhergehenden Veränderungen
        der ihm umgebenden Natur.
      </Intro>
      <Paragraph>
        So schildert es Christoph Theodor Schwab, der ihn ab 1841 des Öfteren
        besuchen kam: »ein schöner heitrer Tag bringt ihn in eine fröhliche,
        sanfte Stimmung, in welcher die Selbstgespräche seltner werden u. seine
        Neigung zum Widersprechen u. Verneinen sich vermindert. Eine schöne
        Mondnacht lockt ihn oft an’s Fenster u. er sieht dann manchmal die halbe
        Nacht lang in’s Freie hinaus.«
      </Paragraph>
      <Constrain>
        <Image
          image={data.mond.childImageSharp.gatsbyImageData}
          caption="Mond"
          alt="Schwarzweiß-Fotografie einer Mond-Spiegelung auf einem See"
        />
      </Constrain>
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Die Stille.">
          <span>Alles schwieg, und schlief, ich wacht' alleine;</span>
          <span>Endlich wiegte mich die Stille ein,</span>
          <span>Und von meinem dunklen Erdbeerhaine</span>
          <span>Träumt' ich, und vom Gang im stillen Mondenschein.</span>
        </QuotePrimary>
      </Constrain>
      <Constrain>
        <Stack>
          <Paragraph>
            Gehörte die Begegnung mit der Natur und Beobachtung der sich darin
            vollziehenden Wechsel zu den elementaren Erfahrungen, aus denen
            Hölderlins Gedichte schöpfen, so bildet das Studium der
            Lichtverhältnisse je nach Tages- und Jahreszeit für Barbara Klemm
            einen Grundbestandteil ihrer fotografischen Praxis. Obgleich ihre
            Bilder schwarz-weiß sind, weiß Barbara Klemm darauf den Morgenhimmel
            mit einem Blick vom Abendhimmel zu unterscheiden. Hölderlin hätte
            das sicherlich auch vermocht.
          </Paragraph>
          <Paragraph>
            <Count>81</Count> Mal hat er in seinen Gedichten die Sonne und{" "}
            <Count>12</Count> Mal den Mond beschrieben, <Count>31</Count> Mal
            den »Morgen«, <Count>239</Count> Mal den Tag und <Count>33</Count>{" "}
            Mal den »Abend«. Die »Nacht« aber ganze <Count>109</Count> Mal.
          </Paragraph>
        </Stack>
      </Constrain>
    </Stack>
  )
}

export default TagNacht
