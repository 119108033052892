import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Container from "../../components/container"
import Stack from "../../components/stack"
import QuotePrimary from "../../components/quote-primary"
import Title from "../../components/title"
import Intro from "../../components/intro"
import Paragraph from "../../components/paragraph"
import Image from "../../components/image"
import Constrain from "../../components/constrain"
import BioKlemm from "./bio-klemm"

const FotografienKlemm = () => {
  const data = useStaticQuery(graphql`
    query {
      neckar: file(
        relativePath: { eq: "hoelderlins-orte/neckar-bei-nuertingen.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Stack>
      <Title top="Fotografien" middle="von" bottom="Barbara Klemm" />
      <Intro>
        Mit ihrem erfahrenen Blick für Anordnungen und Kompositionen,
        Perspektiven und Blickrichtungen hat sich Barbara Klemm den Natur- und
        Landschaftsbeschreibungen aus Hölderlins Gedichten und den Stationen
        seines Lebens zugewandt.
      </Intro>
      <Image
        image={data.neckar.childImageSharp.gatsbyImageData}
        alt="Schwarzweiß-Fotografie des Neckars bei Nürtingen"
        caption="Neckar bei Nürtingen"
      />
      <Constrain align="right">
        <QuotePrimary author="Friedrich Hölderlin: Der Nekar">
          <span>In deinen Thälern wachte mein Herz mir auf</span>
          <span>Zum Leben, deine Wellen umspielten mich,</span>
          <span>Und all der holden Hügel, die dich</span>
          <span>Wanderer! kennen, ist keiner fremd mir.</span>
        </QuotePrimary>
      </Constrain>
      <Stack space={6}>
        <Paragraph>
          Ihre Bilder ergänzen die Orte, die der Dichter selbst vor Augen hatte,
          um jene Sehnsuchtsorte, die er sich lediglich literarisch erschließen
          konnte. Es sind poetische Bilder, die von Versen aus Hölderlins
          Gedichten ausgingen und wieder auf diese zurückverweisen. Wolken-,
          Wasser-, Baum- und Landschaftsstudien sind darunter. Der Himmel nimmt
          darin viel Raum ein – ganz ähnlich wie in Hölderlins Gedichten, die
          stets das Unerreichbare zu greifen suchen.
        </Paragraph>
        <Paragraph>
          So wurden die Bilder zu Dokumenten eines Blickaustauschs: Mit ihrem
          eigenen Blick folgte die Fotografin dem Blick des Dichters. Indem sie
          beide in dieselbe Richtung blickten, begegneten sie sich. Die
          Fotografien fixieren genau jenen Punkt, in dem sich beider Blicke
          trafen.
        </Paragraph>
      </Stack>
      <Container maxWidth="lg">
        <BioKlemm />
      </Container>
    </Stack>
  )
}

export default FotografienKlemm
